import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "my post",
  "slug": "my-post",
  "image": "./img/amsterdam.jpg",
  "date": "2016-07-19T00:00:00.000Z",
  "author": "Belinda Roux"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Amsterdam- Where it all began`}</h1>
    <p>{`Lorem ipsum `}<em parentName="p">{`dolor sit amet`}</em>{`, consectetur adipiscing elit. Proin eget elit lacinia, sollicitudin odio nec, consectetur massa. Nullam mollis euismod est, vitae finibus nibh pretium eu. Sed massa lectus, ornare vel massa vel, varius mattis nisl. Sed cursus eros in augue vestibulum finibus. Sed non sodales risus. Suspendisse sodales a urna vel semper. Cras pharetra accumsan neque, pellentesque sollicitudin ligula egestas eu. `}<strong parentName="p">{`Ut in tincidunt velit`}</strong>{`. In leo felis, ultricies laoreet ligula sit amet, pretium scelerisque metus. Quisque justo urna, venenatis sit amet imperdiet id.
`}<inlineCode parentName="p">{`<h1>Heading Test</h1>`}</inlineCode>{`
Dapibus lobortis tortor.`}</p>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget elit lacinia, sollicitudin odio nec, consectetur massa. Nullam mollis euismod est, vitae finibus nibh pretium eu. Sed massa lectus, ornare vel massa vel, varius mattis nisl. Sed cursus eros in augue vestibulum finibus.`}</p>
    <p><inlineCode parentName="p">{`Sed non sodales risus. Suspendisse sodales a urna vel semper. Cras pharetra accumsan neque, pellentesque sollicitudin ligula egestas eu.`}</inlineCode></p>
    <p>{`Ut in tincidunt velit. In leo felis, ultricies laoreet ligula sit amet, pretium scelerisque metus. Quisque justo urna, venenatis sit amet imperdiet id, dapibus lobortis tortor.`}</p>
    <div className="test">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`const piesang = "pieter piet"
function myFunction(one) {
  console.log(one)
}
myFunction(one)
`}</code></pre>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      